<template>
  <CommonPage id="answer" title="ClarityReport" classs="answer">
    <section class="home-content pc_padding">
      <div class="banner">
        <div class="answer-banner-t-row">
          <div class="answer-banner-txt">
            {{ $t(answerCode) }}
          </div>
        </div>
        <img src="@/assets/home-banner.png" alt="">
        <img src="@/assets/book_pc.png" class="book" alt="">

        <div class="answer-btn" @click="refresh()">
          Ask again
        </div>
      </div>

      <div v-if="Object.keys(translations).length > 0" class="answer-row">
        <AdComponent ref="ads-answer-1" class="m-b-40 w90" :ads="adsensConfig.answer_1"
          :showDebug="showDebug" />
        <div v-for="(translation, lang) in translations" :key="lang">
          {{ translation }}
        </div>
      </div>

      <div class="home-intro-row bg-white">
        <AdComponent ref="ads-answer-2" class="m-b-40 " :ads="adsensConfig.answer_2"
          :showDebug="showDebug" />

        <div class="home-intro-title">
          Something may help
        </div>
        <div class="home-intro-content last">
          <div class="time-page-card-row">
            <div v-for="(data,i) in card" :key="i" class="time-page-card-item"
              @click="goto(data.routename)">
              <div class="time-page-card-image">
                <img :src="data.img" :alt="data.title">
              </div>
              <div class="time-page-card-content">
                <div class="time-page-card-title">
                  {{ data.title }}
                </div>
                <div class="time-page-card-desc">
                  {{ data.desc }}
                </div>
              </div>
              <div class="time-page-card-btn">
                Read more
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/pc_common.vue';
import i18n from '@/i18n';
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/home.scss';

export default {
  name: 'HomeMain',
  components: {
    CommonPage,
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      languages: ['ar', 'zh', 'de', 'en', 'es', 'fr', 'hi', 'ja', 'ko', 'pt', 'ru'],
      translations: {},
      answerCode: null,
      card: [
        {
          img: require('@/assets/NWI1NDQxNDgwYjdiMDYwMjU2NDA5MWU2ZjI3ODEwMGVfQUZlNkc2ZHI3Z2UyUDhkWVV4N0lJcnI3T1UyTUN3N2VfVG9rZW46VzBhWWJxVU9pbzRqSHB4RFBjYmNPY3d0bnlnXzE3MjYyMTExNDY6MTcyNjIxNDc0Nl9WNA.jpg'),
          title: 'Open ClarityReport to Resolve Your Doubts',
          desc: "In our fast-paced lives, we often face the dilemma of making choices. The author has thoughtfully summarized the following excerpts from ClarityReport, hoping that they will guide you to find your life's North Star. This article is especially for new university students.",
          routename: 'blog1'
        },
        {
          img: require('@/assets/YmMwMGNmNWE5NzA3OTgxMDQyNmRiMDBkNDMxNzc2NTZfc3BFRmpVQUtSSlllNkhHZmpDUlJPdnI4ZVZJR0hlMEpfVG9rZW46UGhzU2I2dTY1b0N0Nzh4WmRYSGNlZ3Nibk9nXzE3MjYyMTE2NzE6MTcyNjIxNTI3MV9WNA.webp'),
          title: "You Think It's Normal, But It Could Be Long-Term Anxiety: 9 Signs",
          desc: "Anxiety is an emotion everyone experiences, but few realize that anxiety disorders can severely impact one's life. This psychological condition, commonly known as anxiety disorder, can be hard to detect even by those suffering from it.",
          routename: 'blog2'
        },
        {
          img: require('@/assets/YTVmYTc3NWNmZWQzNTIyNTI0MjU2OTQ5OTE5ZWU4YjBfRHFzMHFPS21Yb3VJZUVqM1dOdmJNeXlhbTh3QVlGRzRfVG9rZW46THo5Z2JUdnlsb2xSVWl4aHZlQWM2YW8wbk9nXzE3MjYyMTIwMzI6MTcyNjIxNTYzMl9WNA.jpg'),
          title: 'Three Steps to Cure Your Decision Paralysis',
          desc: 'Struggling to make a choice between two options, always worrying about missing out or making a mistake, constantly questioning potential risks, and seeking a perfect solution—these are all signs of decision paralysis. Procrastinating until the last moment and feeling exhausted from overthinking can lead to missed opportunities or leaving the decision to someone else.',
          routename: 'blog3'
        },
        {
          img: require('@/assets/YWM5YTEwNjc1M2ZlODgxY2E0NjAyZTBiYzZhYjdiZjdfWUEzWnp5ZHo0eXhxVWFoVTkwTjNzS25kcmMwYWhpZ2NfVG9rZW46WTZWMWJlbDhRb3ZDSXJ4bXg3cmNheXIxbkJoXzE3MjYyMTIxNTk6MTcyNjIxNTc1OV9WNA.jpg'),
          title: 'Stress Management Guide: 5 Scientific Methods to Help You Relieve Stress...',
          desc: 'Transitioning from “busy” to “efficient” involves adopting systematic time management methods and committing to ongoing practice. By setting clear goals, creating detailed plans, prioritizing tasks, overcoming procrastination, managing distractions, adjusting regularly, prioritizing rest, using technological tools, developing good habits, and seeking support, you can significantly improve productivity in both work and life. Mastering these techniques will help you achieve greater organization and efficiency in your daily routines.', routename: 'blog4'
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig"

    ])
  },
  async created () {
    await this.initCode();
    this.loadTranslations();
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    //广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd();
      }, 500);
    },
    async displayAd () {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    //广告相关end
    initCode () {
      this.answerCode = `A${Math.floor(Math.random() * 269) + 2}`;
    },
    refresh () {
      this.initCode();
      this.loadTranslations(); // 调用 loadTranslations 以更新所有翻译
      console.log('refresh');
    },
    loadTranslations () {
      console.log(this.answerCode);
      this.languages.forEach((lang) => {
        this.$set(this.translations, lang, i18n.messages[lang][this.answerCode] || `No translation for ${lang}`);
      });
      console.log(this.translations); // 打印 translations 以确认更新
    },
    goto (name) {
      this.$router.push({ name: name });
    },
  }
}
</script>
